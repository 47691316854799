<template>
  <div>
    <div class="div-input">
      <div :class="['input-custom', { error: isValidate }]">
        <label>
          {{ textFloat }}
          <span v-if="isRequired" class="text-danger">*</span>
          <div class="detail-format">
            {{ text }}
          </div>
        </label>

        <div class="d-flex justify-content-center align-items-center mt-2">
          <label class="mb-0 btn-main" :size="size">
            <input
              type="file"
              v-on:change="handleFileChange"
              :required="required"
              :name="name"
              ref="input"
              :multiple="isMultiple"
              :accept="typeFile"
            />
            <font-awesome-icon
              :icon="['fas', 'folder-open']"
              color="white"
              class="h-100 fa-w-18"
              :size="size"
            />
          </label>
          <div class="w-100 display-only">
            <input
              :class="['custom-input']"
              :type="type"
              :placeholder="placeholder"
              :name="name"
              :required="required"
              :value="fileName"
              :size="size"
              disabled
            />
          </div>
        </div>
      </div>
      <p class="detail-format">
        <span v-if="hasError" class="text-danger ml-2 mt-1"> {{ error }}</span>
      </p>
    </div>
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>
<script>
import axios from "axios";
import ModalLoading from "@/components/alert-modal/ModalLoading";

export default {
  components: {
    ModalLoading
  },
  props: {
    dataList: {
      required: false,
      type: Array
    },
    dataBase64List: {
      required: false,
      type: Array
    },
    textFloat: {
      required: true,
      type: String
    },
    text: {
      required: true,
      type: String
    },
    format: {
      required: true,
      type: String
    },
    fileName: {
      required: true,
      type: String
    },
    required: {
      required: false,
      type: Boolean
    },
    name: {
      required: false,
      type: String
    },
    isRequired: {
      required: false,
      type: Boolean
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    placeholder: {
      required: true,
      type: String
    },
    size: {
      required: false,
      type: String
    },
    downloadPath: {
      required: false,
      type: String
    },
    isMultiple: {
      required: false,
      type: Boolean
    },
    typeFile: {
      required: false,
      type: String
    },
    fileSize: {
      required: false,
      type: Number,
      default: 4194304 // 4MB
    },
    maxFileLength: {
      required: true,
      type: Number
    },
    isDownload: {
      required: false,
      type: Boolean
    },
    isObject: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      file: "",
      value: "",
      type: {
        all: ["image/jpeg", "image/png", "application/pdf", ""],
        file: ["image/jpeg", "image/png", "application/pdf"],
        pdf: ["application/pdf"],
        png: ["image/png"],
        image: ["image/jpeg", "image/png"]
      },
      error: "",
      hasError: false
    };
  },
  methods: {
    handleFileChange(e) {
      this.$refs.modalLoading.show();
      this.hasError = false;
      this.error = "";
      var _validFileExtensions = this.type.file;
      if (this.format == "image") {
        _validFileExtensions = this.type.image;
      }
      let files = e.target.files;
      let file = e.target.files[0];
      let tempBase64 = [];

      var tempBase64List = this.dataList;
      var tempBase64StringList = this.dataBase64List;

      let base64String = "";
      let tempImageName = "";
      if (e.target.files.length > this.maxFileLength) {
        this.value = "";
        this.$refs.input.value = "";
        tempImageName = "";
        tempBase64 = [];
        base64String = "";
        this.error = `*${this.$t("uploadLimited5Photos")}`;
        this.hasError = true;
      } else {
        if (this.isObject) {
          if (_validFileExtensions.indexOf(file.type) < 0) {
            alert("type", file.type);
            this.value = "";
            this.$refs.input.value = "";
            tempImageName = "";
            tempBase64 = [];
            base64String = "";
            this.error = `*${this.$t("invalidFileType")}`;
            this.hasError = true;

            return;
          } else if (file.size > this.fileSize) {
            this.value = "";
            tempImageName = "";
            tempBase64 = [];
            base64String = "";
            this.$refs.input.value = "";
            this.error = `*${this.$t("maximumFile4MB")}`;
            this.hasError = true;

            return;
          } else {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
              let image = await this.saveImagetoDb(reader.result);
              base64String = image;
              tempImageName = file.name;
              this.$emit("onFileChange", {
                filePath: base64String,
                fileBase64: reader.result,
                fileName: tempImageName
              });
            };
          }
        } else {
          [...files].forEach(element => {
            if (_validFileExtensions.indexOf(element.type) < 0) {
              this.value = "";
              this.$refs.input.value = "";
              tempImageName = "";
              tempBase64 = [];
              this.error = `*${this.$t("invalidFileType")}`;
              this.hasError = true;

              return;
            } else if (element.size > this.fileSize) {
              this.value = "";
              tempImageName = "";
              tempBase64 = [];
              this.$refs.input.value = "";
              this.error = `*${this.$t("maximumFile4MB")}`;
              this.hasError = true;

              return;
            } else {
              var reader = new FileReader();
              reader.readAsDataURL(element);
              reader.onload = async () => {
                let image = await this.saveImagetoDb(reader.result);
                tempBase64.push(image);
                if (tempBase64List.length < 5) {
                  tempBase64List.push(image);
                  tempBase64StringList.push(reader.result);
                }
              };

              tempImageName =
                tempImageName + element.name + (files.length > 1 ? ", " : "");
            }
          });

          this.$emit("onFileChange", {
            filePath: tempBase64List,
            fileBase64: tempBase64StringList,
            fileName: tempImageName
          });
        }
      }
      this.$refs.modalLoading.hide();
    },
    onSetImage: async function () {},
    checkFileExtension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim();
    },
    saveImagetoDb: async function (img) {
      this.$store.dispatch("setIsLoadingGlobal", true);
      var imgData = {
        base64: img
      };

      return new Promise((resolve, reject) => {
        this.$axios.post(`${this.$baseUrl}/upload/Save`, imgData).then(data => {
          if (data.data.result == 1) {
            resolve(data.data.detail.url);
            this.$store.dispatch("setIsLoadingGlobal", false);
          } else {
            reject(data.data);
          }
        });
      });
    },
    downloadItem: async function (path) {
      let pathFile = path;
      var name_without_ext = path
        .split("\\")
        .pop()
        .split("/")
        .pop()
        .split(".")[0];

      axios({
        url: pathFile,
        method: "GET",
        // headers: null,
        responseType: "blob"
      }).then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `${name_without_ext}.${response.data.type.split("/").pop(-1)}`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
input[type="file"] {
  display: none;
}
.detail-format {
  color: #9b9b9b;
  font-size: 12px;

  margin-top: 3px;
  margin-bottom: 0px;
}
.bg-icon {
  width: 25px;
  height: 25px;
  margin: 5px 0px;
}
.bg-icon.fa-lg {
  margin: 8px 0px;
}
.div-input {
  margin-bottom: 15px;
  position: relative;
  white-space: nowrap;
}
.input-custom {
  padding: 0px;
}
.input-custom > label {
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
input.custom-input {
  color: #575757;
  background-color: white;
  border: 1px solid #bcbcbc;
  border-radius: 0px 8px 8px 0px;
  padding: 5px 10px;
  // z-index: 0;
}
label[size="lg"].btn-main,
input[size="lg"].custom-input {
  height: 45px;
}
.input-custom.error .custom-input {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: #16274a;
  font-size: 15px;
}
.display-only {
  position: relative;
  // z-index: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.btn-main {
  width: 80px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: red;
  border-radius: 8px 0px 0px 8px;
}
.btn-download-file {
  padding: 0;
  text-decoration: underline;
}
.svg-inline--fa.fa-w-18 {
    width: 1.125em;
}
@media (max-width: 767.98px) {
  .input-custom > label {
    font-size: 15px;
  }
  .detail-format {
    font-size: 11px;
  }
}
</style>
