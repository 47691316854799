<template>
  <div class="h-100vh position-relative bg-light-gray">
    <div class="logo-image-container text-center complain-header py-3">
      <img src="@/assets/logo-d-dot.svg" class="logo-img" />
    </div>
    <div class="d-flex justify-content-center w-100 complain-container" v-if="form.UserGUID">
      <div class="w-100 my-3">
        <div class="d-flex justify-content-center">
          <div class="card p-3 mx-3 complain-card complain-position mb-5">
            <template>
              <b class="text-center f-size-20 mb-3">{{$t('reportForm')}}</b>
              <b-row>
                <b-col class="custom-mt-2">
                  <InputText
                    :textFloat="$t('topicName')"
                    :placeholder="$t('topicName')"
                    type="text"
                    name="Topic"
                    isRequired
                    v-model="form.Topic"
                    :isValidate="$v.form.Topic.$error"
                    :v="$v.form.Topic"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <InputTextArea
                    :textFloat="$t('description')"
                    :placeholder="$t('description')"
                    type="text"
                    name="Description"
                    isRequired
                    v-model="form.Description"
                    :isValidate="$v.form.Description.$error"
                    :v="$v.form.Description"
                    rows="8"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <UploadFilePanel
                    :dataList="form.Attachment"
                    :dataBase64List="fileBase64List"
                    :textFloat="$t('attachedFile')"
                    :placeholder="$t('pleaseSelectFile')"
                    :fileName="fileName"
                    v-model="form.Attachment"
                    format="all"
                    name="fileUpload"
                    :text="$t('fileValidate2')"
                    :maxFileLength="5"
                    v-on:onFileChange="onImageChange"
                    isMultiple
                    typeFile="image/jpeg, image/png"
                    size="lg"
                    v-bind:isValidate="$v.form.Attachment.$error"
                    :v="$v.form.Attachment"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col class="d-flex image-list-panel overflow-auto">
                  <div v-for="(item, index) in form.Attachment" :key="index">
                    <div
                      v-if="checkFileExtension(item).toLowerCase() == 'pdf'"
                      class="d-flex"
                    >
                      <font-awesome-icon
                        class="icon-pdf"
                        icon="file-pdf"
                        color="#8f20c6"
                      />

                      <font-awesome-icon
                        class="icon-pdf-delete"
                        icon="times-circle"
                        color="#E0E0E0"
                        @click="deleteImage(index)"
                      />
                    </div>
                    <div
                      v-else-if="checkFileExtension(item).toLowerCase() == 'mp4'"
                      class="
                        panel-bg-file-image
                        position-relative
                        p-0
                        embed-responsive
                        banner-video
                        embed-responsive-16by9
                      "
                    >
                      <font-awesome-icon
                        class="icon-play"
                        icon="play-circle"
                        color="#FFFFFF"
                      />
                      <video ref="videoRef" class="w-100 video-box">
                        <source :src="item" type="video/mp4" />
                      </video>
                      <font-awesome-icon
                        class="icon-pdf-delete"
                        icon="times-circle"
                        color="#E0E0E0"
                        @click="deleteImage(index)"
                      />
                    </div>
                    <div
                      v-else
                      class="panel-bg-file-image mr-1 mb-1"
                      v-bind:style="{
                        backgroundImage: 'url(' + item + ')'
                      }"
                    >
                      <font-awesome-icon
                        class="icon-delete"
                        icon="times-circle"
                        color="#E0E0E0"
                        @click="deleteImage(index)"
                      />
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <InputText
                    :textFloat="$t('email')"
                    :placeholder="$t('email')"
                    type="email"
                    name="Email"
                    isRequired
                    v-model="form.Email"
                    :isValidate="$v.form.Email.$error"
                    :v="$v.form.Email"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <InputText
                    :textFloat="$t('firstname')"
                    :placeholder="$t('firstname')"
                    type="text"
                    name="Firstname"
                    isRequired
                    v-model="form.Firstname"
                    :isValidate="$v.form.Firstname.$error"
                    :v="$v.form.Firstname"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <InputText
                    :textFloat="$t('lastname')"
                    :placeholder="$t('lastname')"
                    type="text"
                    name="Lastname"
                    isRequired
                    v-model="form.Lastname"
                    :isValidate="$v.form.Lastname.$error"
                    :v="$v.form.Lastname"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <InputText
                    :textFloat="$t('telephone')"
                    :placeholder="$t('telephone')"
                    type="tel"
                    name="telephone"
                    @onKeypress="isNumber($event)"
                    isRequired
                    v-model="form.Phone"
                    :isValidate="$v.form.Phone.$error"
                    :v="$v.form.Phone"
                  />
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <b-form-checkbox
                    id="checkbox-1"
                    name="checkbox-1"
                    v-model="form.Consent"
                  >
                    <span>{{ $t("consentMessage4") }}</span
                    ><a
                      href="https://google.com"
                      target="_blank"
                      class="main-color"
                      >{{ $t("consentMessage5") }}</a
                    ><span>{{ $t("consentMessage6") }}</span>
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </template>
            <b-row class="my-3">
              <b-col>
                <b-button
                  :disabled="
                    (!form.Consent) || isSaveForm
                  "
                  @click="checkForm"
                  class="w-100 btn-main py-2"
                  >{{ $t("submit3") }}</b-button
                >
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlert
      ref="modalAlert"
      :text="modalMessage"
      v-on:hadleModalAlertClose="hadleModalAlertClose"
    />
    <ModalAlertError
      ref="modalAlertError"
      :text="modalMessage"
    />
  </div>
</template>

<script>
import InputText from "@/components/input/InputText";
import InputTextArea from "@/components/input/InputTextArea";
import UploadFilePanel from "@/components/complain/UploadFilePanel";
import { required, helpers, email } from "vuelidate/lib/validators";
import ModalLoading from "@/components/alert-modal/ModalLoading";
import ModalAlert from "@/components/alert-modal/ModalAlert";
import ModalAlertError from "@/components/alert-modal/ModalAlertError";
const alpha = helpers.regex("alpha", /^[\u0E00-\u0E7Fa-zA-Z ']*$/);

export default {
  components: {
    InputText,
    InputTextArea,
    UploadFilePanel,
    ModalLoading,
    ModalAlert,
    ModalAlertError
  },
  data() {
    return {
      index: null,
      sessionId: "",
      lineProfile: {
        userId: "",
        displayName: "",
        pictureUrl: "",
        statusMessage: ""
      },
      modalMessage: "",
      nameList: [],
      isDelete: false,
      form: {
        UserGUID: "",
        Topic: "",
        Description: "",
        File: "",
        Attachment: [],
        Firstname: "",
        Lastname: "",
        Email: "",
        Phone: "",
        Consent: false,
      },
      fileName: "",
      fileUpload: "",
      fileBase64List: [],
      isSaveForm: false,
    };
  },
  validations: {
    form: {
      UserGUID: {},
      Topic: { required },
      Description: { required },
      Firstname: { required, alpha },
      Lastname: { required, alpha },
      Phone: { required },
      Email: { required, email },
      Consent: {},
      Attachment: {}
    }
  },
  beforeCreate: async function () {
    if (this.$liff.isInClient()) {
      this.$liff
        .init({ liffId: this.$liff_Main_ID })
        .then(() => {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
          });
        })
        .catch(error => {
          console.error("error", error);
        });
    }
  },
  created: async function () {
    this.sessionId = this.$route.query.sessionId;
    if(this.sessionId){
      await this.getUserGUID()
    } else {
      this.$router.push('/session-expired')
    }
  },

  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    hadleModalAlertClose() {
      if (this.$liff.isInClient()) {
        this.$liff.closeWindow();
      } else {
        this.$router.push("/success/complain");
      }
    },
    checkForm: async function () {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }
      this.submit(this.form);
    },
    submit: async function (body) {
      this.$refs.modalLoading.show();
      this.isSaveForm = true;
      await this.$axios
        .post(`${this.$baseUrl}/complain/save`, body)
        .then(data => {
          if (data.data.result == 1) {
            this.isSaveForm = false;
            if (this.$liff.isInClient()) {
              this.modalMessage = this.$t("messageDoneSuccess");
              this.$refs.modalLoading.hide();
              this.$refs.modalAlert.show();
              setTimeout(() => {
                this.hadleModalAlertClose();
              }, 1000);
            } else {
              this.modalMessage = this.$t("messageDoneSuccess");
              this.$refs.modalAlert.show();
              setTimeout(() => {
                window.close();
              }, 1000);
              //this.$router.push("/success/complain");
            }
          } else {
            this.isSaveForm = false;
            this.modalMessage = data.data.detail[0];
            this.$refs.modalLoading.hide();
            this.$refs.modalAlertError.show();
          }
        });
    },
    checkFileExtension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim();
    },
    deleteImage: async function (index) {
      this.isDelete = true;
      this.form.Attachment.splice(index, 1);
      this.fileBase64List.splice(index, 1);
      let arr = this.fileName.split(",");
      arr = arr.map(el => el.trim());
      await arr.splice(index, 1);
      this.fileName = "";
      arr.forEach(element => {
        this.fileName = this.fileName + element + (arr.length > 1 ? " " : "");
      });
      setTimeout(() => {
        this.isDelete = false;
      }, 500);
    },
    onImageChange(value) {
      let arrName = this.fileName.split(",");
      arrName = arrName.map(el => el.trim());
      if (arrName.length < 6) this.fileName = this.fileName + value.fileName;

      this.form.Attachment = value.filePath;
      this.fileBase64List = value.fileBase64;
    },
    getUserGUID: async function(){
      await this.$axios
        .get(`${this.$baseUrl}/user/${this.sessionId}`)
        .then((result) => {
          if(result.data.result === 1){
            if(!result.data.detail.userGUID) this.$router.push('/session-expired')

            this.form.UserGUID = result.data.detail.userGUID
            this.form.Firstname = result.data.detail.firstname
            this.form.Lastname = result.data.detail.lastname
            this.form.Email = result.data.detail.email
            this.form.Phone = result.data.detail.telephone
          } else this.$router.push('/session-expired');
        }).catch( () => {
            this.$router.push('/session-expired')
        });
    },
  }
};
</script>

<style lang="scss" scoped>
.icon-play {
  position: absolute;
  top: 30%;
  left: 27%;
  z-index: 99;
  color: white;
  width: 40px;
  height: 40px;
}

::v-deep .custom-radio .custom-control-label::before {
  border-radius: 0.25rem;
}
::v-deep
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.text-error {
  color: #dc3545 !important;
  font-weight: bold;
  font-size: 16px;
}

.complain-container {
  height: calc(100vh - 60.5px);
}
.complain-card {
  width: 100%;
}
.complain-position {
  position: relative;
}
.icon-pdf-delete {
  position: absolute;

  top: 4%;
}
::v-deep .card {
  border-color: white;
  border-radius: 14px;
}

.icon-pdf {
  width: 100px;
  height: 100px;
  cursor: pointer;
}
.panel-bg-file-image {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100px;
  height: 100px;
  cursor: pointer;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  text-align: right;
  padding-right: 3px;
}
.detail-format {
  color: #9b9b9b;
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: 0px;
}
.text-label {
  color: #58595b;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}

.btn-language {
  background: white !important;
  color: #8f20c6 !important;
  border: none;
}

.position-btn-language {
  position: absolute;
  right: 15px;
  top: 10px;
}

::v-deep .col-form-label {
  color: #58595b;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}

::v-deep .cool-lightbox-video {
  width: auto !important;
  height: auto !important;
}
::v-deep .cool-lightbox-button {
  background-color: transparent !important;
}
@media only screen and (max-width: 400px) {
  .custom-mt-2 {
    margin-top: 10px;
  }
}
@media only screen and (min-width: 767.98px) {
  .complain-card {
    max-width: 800px;
  }
  .detail-format {
    font-size: 11px;
  }
  .text-error,
  .text-label {
    font-size: 15px;
  }
  ::v-deep .col-form-label {
    font-size: 15px;
  }
}
</style>
