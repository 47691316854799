<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="alertModal"
      hide-header
      hide-footer
      centered
      @hidden="hadleModalAlertErrorClose()"
    >
      <div class="modal-header">
        <button type="button" aria-label="Close" class="close" @click="hide">
          ×
        </button>
      </div>
      <div class="text-center p-3">
        <font-awesome-icon icon="times-circle" class="icon-error" />
        <p class="text-text" v-if="text && !isErrorList">{{ text }}</p>
        <div v-else>
          <p
            v-for="(t, index) in text"
            :key="index"
            class="text-text f-size-14"
          >
            {{ t }}
          </p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      required: false,
      type: [String, Array],
    },
    isErrorList: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    hadleModalAlertErrorClose() {
      this.$emit("hadleModalAlertErrorClose");
    },
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
.modal-header {
  border: 0 !important;
  padding: 0;
}
.text-text {
  color: #16274a;
  margin-bottom: 0;
  font-size: 26px;
}
.modal-body {
  margin: auto;
  text-align: center;
}
.img {
  width: 100px;
}
.f-size-14 {
  font-size: 14px;
}
.icon-error {
  font-size: 130px;
  color: #F55D67;
  margin-bottom: 15px;
}
@media (max-width: 767.98px) {
  /* .text-text {
    font-size: 15px;
  } */
}
</style>